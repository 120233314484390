import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../Stylesheets/ProjectPage.css";
import RwaworldApi from "../api";
import twitter from "../Assets/twitter.png";
import discord from "../Assets/discord.png";
import discordoriginal from "../Assets/discordoriginal.png";
import twitteroriginal from "../Assets/twitteroriginal.png";

import nologo from "../Assets/nologo.png";
import twitterspace from "../Assets/Twitter spaces.png";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
  ChevronLeftIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { justify } from "@cloudinary/url-gen/qualifiers/textAlignment";

const IndividualProject = () => {
  const { projectName } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const projectLogos = {
    Algorand:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062244/algorand_lyxi5i.webp",
    Arbitrum:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062245/Arbitrum_aesfqj.jpg",
    Avalanche:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062245/Avalanche_ulnxbu.webp",
    Base: "https://res.cloudinary.com/dqpftskf5/image/upload/v1739462208/base_tbeqoy.png",
    BNB: "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062246/binance_pahyy8.webp",
    Canto:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065181/canto-network_uxojbx.webp",
    Celo: "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065184/celo_nnpugq.webp",
    "Arbitrum One":
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065173/arbitrum_one_ytpeng.webp",
    "Polkadot Parachain":
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062249/polkadot_wa4mgx.webp",
    Creditcoin:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065187/creditcoin_peswr4.webp",
    Ethereum:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062248/ethereum_e8gjjt.webp",
    "Hedera Hashgraph":
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065190/hedera_hashgraph_ya2ty9.webp",
    Hyperledger: "https://example.com/polygon_logo.png",
    Kinto: "https://example.com/polygon_logo.png",
    "Onyx (by JPMorgan)": "https://example.com/polygon_logo.png",
    Optimism:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062249/Optimism_rvctwp.webp",
    Polygon:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062250/polygon_ux9lkb.webp",
    Polymesh:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065199/polymesh_l7ocv1.webp",
    Provenance: "https://example.com/polygon_logo.png",
    Solana:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062251/solana_jfjwbh.webp",
    Stellar:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062251/Stellar_symbol_black_RGB_g9l8b9.webp",
    xDai: "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065215/xdai_hvmxnm.webp",
    "R3 Corda":
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065200/R3_Corda_sefkf9.png",
    Cosmos:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065742/cosmos_lzjkg8.webp",
    Aptos:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062244/aptos_round_mujngt.webp",
    Near: "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062248/near_ienfpq.webp",
    Sei: "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062250/sei_goc2vm.webp",
    XDC: "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065217/xdc_vlsl8m.webp",
    "Plume Network":
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065194/plume_network_f2xlbc.jpg",
    "Realio Network":
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065202/realio_ggphcs.webp",
    "XRP Ledger":
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709062254/xrp_qbepce.webp",
    Sui: "https://res.cloudinary.com/dqpftskf5/image/upload/v1709065208/sui_ezmh56.webp",
    Gnosis: "https://res.cloudinary.com/dqpftskf5/image/upload/v1711721410/gnosis_dyxtgt.png",
    Tezos: "https://res.cloudinary.com/dqpftskf5/image/upload/v1712372479/tezos_dpikjz.png",
    ToroNet:
      "https://res.cloudinary.com/dqpftskf5/image/upload/v1709357968/rwa%20world/toronet_x0nxu8.jpg",
    "-": "https://res.cloudinary.com/dqpftskf5/image/upload/v1709066334/noChain_pbqera.png",
    "?": "https://res.cloudinary.com/dqpftskf5/image/upload/v1709066334/noChain_pbqera.png",
  };

  const twitterSpaces = {
    "Cicada Partners": "https://twitter.com/RwaWorld/status/1765744599140557237",
    Lofty: "https://twitter.com/RwaWorld/status/1763246175707189488",
    Unlockd: "https://twitter.com/RwaWorld/status/1761020578406822180",
    "Cogito Finance": "https://twitter.com/RwaWorld/status/1758142679626535374",
    Blockapps: "https://twitter.com/RwaWorld/status/1755682097396638100",
    Zivoe: "https://twitter.com/ZivoeFinance/status/1752785068806824401",
    Spydra: "https://twitter.com/spydra_io/status/1750179393346101516",
    Uranium3o8: "https://twitter.com/RwaWorld/status/1750142055731384630",
    "Florence Finance": "https://twitter.com/RwaWorld/status/1730272301768216749",
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectDetails = await RwaworldApi.getProject(projectName);
        if (!projectDetails) {
          throw new Error("Project details not found");
        }
        projectDetails.summary = projectDetails.summary.split("\n");

        const fixedChain = Array.isArray(projectDetails.chain)
          ? projectDetails.chain
          : [projectDetails.chain];
        // Remove unnecessary characters from each chain name
        const cleanedChain = fixedChain.map((chain) =>
          chain
            .replace(/["{}]/g, "")
            .split(",")
            .map((c) => c.trim())
        );
        projectDetails.chain = cleanedChain.flat();
        setProject(projectDetails);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching project data:", error);
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [projectName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div id="project">
      <div className="individual-project">
        {/* <div>
      
        </div> */}
        <div className="namebox">
          {" "}
          <button id="gobackbutton" onClick={() => navigate(-1)}>
            <ChevronLeftIcon
              id="lefticon"
              className="h-3 w-3 flex-none text-gray-400 dark:text-white"
              aria-hidden="true"
            />
          </button>
          {project.name}
          <img
            src={project.logo || nologo}
            alt={`${project.name} Logo`}
            className="project-logo"
          />
        </div>

        <div className="row">
          <div className="box" id="aboutbox">
            <span id="aboutboxspan">About</span>

            {project.summary.map((paragraph, index) => (
              <p id="aboutsummaryparagraph" key={index}>
                {paragraph}
              </p>
            ))}
            <div id="socialmediaboxdiv">
              <a
                id="twitterlogoaboutpage"
                href={project.twitter}
                target="_blank"
              >
                <img src={twitteroriginal} />
              </a>
              {/* <a id="discordlogo" href={project.discord} target="_blank">
                <img src={discordoriginal} />
              </a> */}
              {project.discord && (
                <a id="discordlogo" href={project.discord || "rwa.world"} target="_blank">
                  <img src={discordoriginal} />
                </a>
              )}

              <div id="websiteurl">
                <a href={project.url} target="_blank">
                  <span id="websiteurlspan">
                    {" "}
                    <ArrowTopRightOnSquareIcon className="h-5 w-5 flex-none text-gray-400 dark:text-white" />
                    {project.url}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="box project-other-box">
            {/* <img
              src={project.logo || nologo}
              alt={`${project.name} Logo`}
              className="project-logo"
            /> */}
            <div className="row1">
              <div className="left-half">
                {" "}
                <span id="aboutboxspan">Other</span>
                <br />

                {project.product_category &&
                project.product_category !== "?" &&
                project.product_category !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Product Category:</span>
                    <div id="boxdata2"> {project.product_category}</div>
                  </div>
                )}
              {project.issuer_asset_category &&
                project.issuer_asset_category !== "?" &&
                project.issuer_asset_category !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Asset Category:</span>{" "}
                    <div id="boxdata"> {project.issuer_asset_category}</div>
                  </div>
                )}
              {project.asset_sub_categories &&
                project.asset_sub_categories !== "?" &&
                project.asset_sub_categories !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Sub-Category:</span>{" "}
                    
                    
                    <div id="boxdata">  {project.asset_sub_categories}</div>
                  </div>
                )}
              {project.launch_status &&
                project.launch_status !== "?" &&
                project.launch_status !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Launch Status:</span>{" "}
                    <div id="boxdata">{project.launch_status}</div>
                  </div>
                )}
              <div className="box1">
                <span id="boxspan">Chain:</span>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {project.chain.map((chainName, index) => (
                    <div
                      id="chainLogo"
                      key={index}
                      style={{ marginRight: "5px" }}
                      title={chainName} // Set the chain name as tooltip
                    >
                      <img
                        src={projectLogos[chainName]}
                        alt={chainName}
                        className="chain-logo"
                      />
                    </div>
                  ))}
                </div>
              </div>
              {project.custodial_non_custodial &&
                project.custodial_non_custodial !== "?" &&
                project.custodial_non_custodial !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Custodial or Non-Custodial:</span>{" "}
                    <div id="boxdata"> {project.custodial_non_custodial}</div>
                  </div>
                )}
              {project.project_tokens &&
                project.project_tokens !== "?" &&
                project.project_tokens !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Project Tokens:</span>{" "}
                    <div id="boxdata"> {project.project_tokens}</div>
                  </div>
                )}
              {project.founded &&
                project.founded !== "?" &&
                project.founded !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Founded:</span> 
                    <div id="boxdata"> {project.founded}</div>
                  </div>
                )}
              {project.location &&
                project.location !== "?" &&
                project.location !== "-" && (
                  <div className="box1">
                    <span id="boxspan">Location:</span> 
                    <div id="boxdata">{project.location}</div>{" "}
                  </div>
                )}
              {project.private_public_blockchain &&
                project.private_public_blockchain !== "?" &&
                project.private_public_blockchain !== "-" && (
                  <div className="box1">
                    <span id="boxspan">
                      Public or Private Blockchain:
                    </span>{" "}
                    <div id="boxdata"> {project.private_public_blockchain}</div>
                  </div>
                )}
     
                  {/* <span id="boxspan">Public or Private Blockchain:</span>{" "}
                  {project.private_public_blockchain} */}
                </div>
                {/* <div className="box1">
                  <div className="box1" id="chainlogodiv">
                    <span id="boxspan">Chain:</span>
                    {project.chain.map((chainName, index) => (
                      <div id="chainLogo" key={index}>
                        <img
                          src={projectLogos[chainName]}
                          alt={chainName}
                          className="chain-logo"
                        />
                      </div>
                    ))}
                  </div>
                </div> */}
              </div>
              {twitterSpaces[project.name] && (
                <div id="twitterspace" className="right-half">
                  <span>
                    Check out our exclusive conversation with {project.name}:
                  </span>
                  <a href={twitterSpaces[project.name]} target="_blank">
                    <img
                      id="twitterspacelogo"
                      src={twitterspace}
                      alt="Twitter Spaces"
                    />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export default IndividualProject;
